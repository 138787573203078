import common from '@/mixin/common.js'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'r-search',
  mixins: [common],
  data() {
    return {
      searchText: null,
      markImg: require('@/assets/ic_mark.png'),
	  pageNum:1,
	  pageSize:6
    }
  },
  mounted() {
    // this.scrollToTop();
    this.initInputInfo();
  },
  methods:{
    ...mapActions('search', ['getArticleList']),
    clear() {
      this.$refs.input_info.value = null;
    },
    initInputInfo() {
      if (!this.$refs.input_info) {
        setTimeout(()=>{
          this.initInputInfo();
        }, 10)
        return;
      }
      this.$nextTick(()=>{
        this.$refs.input_info.value = decodeURIComponent(this.$route.params.text) || null;
        this.onSearch();
      })
    },
    onSearch(){
      const inputInfo = this.$refs.input_info.value;
      if (!inputInfo) {
				this.$message.warning('请输入所需要搜索的内容');
        return;
      }
      this.searchText = inputInfo;
	  console.log('searchText',this.searchText)
      this.getArticleList({
        text: inputInfo,
		pageNum: this.pageNum,
		pageSize: this.pageSize
      })
    },
	loadMoreList(){
		this.pageSize+=3
		this.getArticleList({
        text: this.searchText,
		pageNum: this.pageNum,
		pageSize: this.pageSize
      })
	},
    getMoreInfo() {
      this.getArticleList({
        text: this.searchText,
        isReset: false,
      })
    },
    textChange(params = null) {
      if (!params) return '';
      return params.replace(this.searchText, `<span style="color:#417dc9">${this.searchText}</span>`);
    },
    showDetail(params) {
      if (!params || !params.id) {
        return;
      }
      this.$router.push(`/news/detail/${params.code}`);
    }
  },
  computed: {
    ...mapGetters('search', ['articleList', 'haveMore']),
  }
}
